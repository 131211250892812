import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "./light.js";

import Carusell from "bricks/Carusell.js"
import ScrollToButt from "./ScrollToButt";
import ScrollIntoView from 'react-scroll-into-view';

import ReactTypingEffect from 'react-typing-effect';

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  background-image: url("https://s.meonao.com/carusel/black.svg");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-4xl text-center justify-center items-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="about">
        О нас
      </NavLink>
      <NavLink href="npds2">
        Магазин
      </NavLink>
      <NavLink href="net">
        Сеть
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="/hello">
        ⚡️
      </PrimaryLink>
    </NavLinks>
  
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <Content>
          <Heading>
              Да,
              <br />
              уже очень долго
              <br />
              зато <ReactTypingEffect
				text={["хорошо", "удобно", "вкусно", "феерично", "грамотно", "достойно", "душевно", "надёжно"]}
				speed={125}
				eraseSpeed={75}
				eraseDelay={800}
				typingDelay={100}
				cursorRenderer={cursor => <h1>{cursor}</h1>}
				displayTextRenderer={(text, i) => {
				  return (
					<h1>
					  {text.split('').map((char, i) => {
						const key = `${i}`;
						return (
						  <span
							key={key}
						  >{char}</span>
						);
					  })}
					</h1>
				  );
				}}        
			  />
			  <br />
			  <img src="https://s.meonao.com/icons/burger.svg" alt="burger" style={{borderRadius: '2px', width: '1.5em', margin: 'auto', marginTop: '0.5em'}} />
          </Heading>
          <ScrollIntoView selector="#features">
		  <img src="https://s.meonao.com/icons/arrow-down.svg" alt="arrow-down" style={{borderRadius: '10px', width: '4em', marginTop: '5em'}} />
		  </ScrollIntoView>
        </Content>
      </HeroContainer>
    </Container>
  );
};